import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MOBILE_RECOVERY_STATUS } from '@interfaces/mobile-recovery-interface';
import { MobileRecoveryModel, MobileRecoveryPartnerDataModel } from '@models/cart/mobile-recovery.model';
import { DialogRef } from '@ngneat/dialog';
import { MobileRecoveryService } from '@services/mobile-recovery.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'tlv-mobile-recovery-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: false
})
export class MobileRecoverySearchComponent implements OnInit {

    @Output() goToNextStep = new EventEmitter<void>();

    public selectedMobileReco: MobileRecoveryModel;

    public searchText: string;
    public filtredProducts: MobileRecoveryModel[] = [];


    constructor(
        private mobileRecoveryService: MobileRecoveryService,
        public ref: DialogRef
    ) { }

    public ngOnInit(): void {

        this.selectedMobileReco = this.mobileRecoveryService.currentMobileRecovery;

        if (this.selectedMobileReco) {
            this.searchText = this.selectedMobileReco.name;
        }
    }

    public clearSearch(): void {
        this.searchText = '';
        this.selectedMobileReco = new MobileRecoveryModel({
            id: null,
            name: null,
            partnerData : new MobileRecoveryPartnerDataModel({
                minAmount: null,
                maxAmount: null,
                status: MOBILE_RECOVERY_STATUS.UNSTARTED
            }),
            brand: null
        });
        this.mobileRecoveryService.update(this.selectedMobileReco);
        this.selectedMobileReco = this.mobileRecoveryService.currentMobileRecovery;
    }

    public next(): void {
        this.goToNextStep.emit();
    }

    public changeSearchText(event: string): void {
        if (event.length > 2) {
            this.mobileRecoveryService.getPartnerProducts(event)
                .pipe(debounceTime(200))
                .subscribe((data) => {
                    this.filtredProducts = data;
                });
        }
    }

    public selectedItem(selectedItem: MobileRecoveryModel): void {
        this.selectedMobileReco = selectedItem;
        this.searchText = selectedItem.name;
        this.selectedMobileReco.partnerData.status = MOBILE_RECOVERY_STATUS.ONGOING;
        this.selectedMobileReco.partnerData.bonus = this.mobileRecoveryService.currentMobileRecovery.partnerData.bonus || 0;
        this.mobileRecoveryService.update(this.selectedMobileReco);
        this.filtredProducts = [];
    }
}
