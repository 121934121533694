import { Pipe, PipeTransform } from '@angular/core';
import { FeatureMobileRecoveryModel } from '@models/cart/feature-mobile-recovery.model';
import { SurveyMobileRecoveryModel } from '@models/cart/quote-mobile-recovery.model';

@Pipe({
    name: 'filterFeaturesDescription',
    pure: false,
    standalone: false
})
export class FilterFeaturesPipe implements PipeTransform {

    public transform(feature: FeatureMobileRecoveryModel, surveys: SurveyMobileRecoveryModel[] = []): string[] {
        const survey = surveys.find((q)=> q.id === feature.id);
        return feature.responses.some((res) => res.label === survey?.response.value) ?
            feature.responses.find((res) => res.label === survey?.response.value).description : feature.responses[0].description;
    }
}
