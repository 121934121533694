import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MOBILE_RECOVERY_STATUS, ResponseFeature } from '@interfaces/mobile-recovery-interface';
import { FeatureMobileRecoveryModel } from '@models/cart/feature-mobile-recovery.model';
import { MobileRecoveryModel } from '@models/cart/mobile-recovery.model';
import { MobileRecoveryService } from '@services/mobile-recovery.service';

@Component({
    selector: 'tlv-mobile-recovery-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss'],
    standalone: false
})


export class MobileRecoveryListingComponent implements OnInit {

    @Output() goToNextStep = new EventEmitter<void>();
    @Output() goToPreviousStep = new EventEmitter<void>();
    public selectedMobileReco: MobileRecoveryModel;
    public features: FeatureMobileRecoveryModel[] = null;
    public missingResponses: FeatureMobileRecoveryModel[];
    public isLoading = false;
    public formIncomplete = true;

    constructor(
        private mobileRecoveryService: MobileRecoveryService
    ) { }

    public ngOnInit(): void {
        this.selectedMobileReco = this.mobileRecoveryService.currentMobileRecovery;
        this.selectedMobileReco.partnerData.status = MOBILE_RECOVERY_STATUS.UNFINISHED;
        this.mobileRecoveryService.update(this.selectedMobileReco);
        this.mobileRecoveryService.getPartnerProductFeatures()
            .subscribe((data) => {
                this.features = data;
                if (this.selectedMobileReco.partnerData.surveys.length === this.features.length) {
                    this.formIncomplete = false;
                }
            });

    }

    public selectFeature(evt: Event, selectedFeature: FeatureMobileRecoveryModel): void {
        const value: string = (evt.target as HTMLInputElement).value;
        this.features = this.features.map((feature) => feature.id === selectedFeature.id ?
            this._setFeatureValues(feature, value) : feature );
        if (this.selectedMobileReco.partnerData.surveys.length === this.features.length) {
            this.formIncomplete = false;
        }
    }

    public next(): void {
        if (this.selectedMobileReco.partnerData.surveys.length === this.features.length) {
            this.goToNextStep.emit();
        }
    }

    public previous(): void {
        this.goToPreviousStep.emit();
    }

    public getSelectedOption(feature: FeatureMobileRecoveryModel, val: string): boolean {
        return this.selectedMobileReco.partnerData.surveys?.length ?
            this.selectedMobileReco.partnerData.surveys.find((q)=> q.id === feature.id)?.response.value === val : false;
    }

    private _setFeatureValues(feature: FeatureMobileRecoveryModel, value: string): FeatureMobileRecoveryModel{
        const response: ResponseFeature = feature.responses.find((res) => res.label === value);
        this.selectedMobileReco.partnerData.surveys = this.selectedMobileReco.partnerData.surveys
            ?.filter((quote) => quote.id !== feature.id);
        this.selectedMobileReco.partnerData.surveys.push({
            id: feature.id,
            question: feature.label,
            response:{
                id: response.id,
                value: response.label
            }});
        this.selectedMobileReco.partnerData.surveys.sort((a, b) => a.id.localeCompare(b.id));
        return feature;
    }

}
