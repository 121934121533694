/* eslint-disable */
export enum TELESALES_URLS {
    box = 'products/device_faim_telesales',
    phone_simple = 'products/phone_telesales',
    faim = 'products/faim_telesales',
    plan_premium = 'products/plan_premium_telesales',
    fai_result = 'fai/techno',
    plan_simo = 'products/plan_sowo_telesales',
    faim_premium = 'products/faim_telesales',
    fai = 'fai/address',
    prompto = 'products/prompto_telesales',
    plan_sowo ='products/plan_sowo_telesales',
}
