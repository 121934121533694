import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MOBILE_RECOVERY_STATUS } from '@interfaces/mobile-recovery-interface';
import { MobileRecoveryModel } from '@models/cart/mobile-recovery.model';
import { DialogRef } from '@ngneat/dialog';
import { MobileRecoveryService } from '@services/mobile-recovery.service';

@Component({
    selector: 'tlv-mobile-recovery-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss'],
    standalone: false
})
export class MobileRecoveryResultComponent implements OnInit {

    @Output() goToNextStep = new EventEmitter<void>();
    @Output() goToPreviousStep = new EventEmitter<void>();
    @Output() goToSearchStep = new EventEmitter<void>();
    public selectedMobileReco: MobileRecoveryModel;
    public choiceInsurance = false;
    public hasClickInsurance = false;
    public checkCondition = false;
    public amountInsurance = 5;
    public totalAmount = 0;
    public MONTANT_MINIMUM_REPRISE = 10;
    public isLoading = true;

    constructor(
        private ref: DialogRef,
        private mobileRecoveryService: MobileRecoveryService) {
        this.selectedMobileReco = this.mobileRecoveryService.currentMobileRecovery;
        this.callTotalPrice();
    }

    public ngOnInit(): void {
        this.mobileRecoveryService.getQuotePartner()
            .subscribe(
                () => {
                    this.selectedMobileReco = this.mobileRecoveryService.currentMobileRecovery;
                    this.callTotalPrice();
                    this.isLoading = false;
                }
            );
    }

    public close(force = false): void {
        if (this.checkCondition && this.hasClickInsurance || force) {
            if (this.selectedMobileReco.prices.base > 0) {
                this.mobileRecoveryService.currentMobileRecovery.partnerData.status = MOBILE_RECOVERY_STATUS.COMPLETED;
                this.mobileRecoveryService.update(this.mobileRecoveryService.currentMobileRecovery);
            }
            this.ref.close();
        }
    }


    public callTotalPrice(): void {
        const assurance = this.choiceInsurance ? this.selectedMobileReco.partnerData.insuranceAmount : 0;
        this.totalAmount = this.selectedMobileReco.prices.final + this.selectedMobileReco.partnerData.bonus - assurance;
    }

    public selectInsurance(evt: Event): void {
        const value: string = (evt.target as HTMLInputElement).value;
        this.hasClickInsurance = true;
        this.choiceInsurance = this.selectedMobileReco.partnerData.hasInsurance = value.toLowerCase() === 'oui';
        this.mobileRecoveryService.update(this.selectedMobileReco);
        this.callTotalPrice();
    }

    public previous(): void {
        this.goToPreviousStep.emit();
    }

    public goToSearch(): void {
        this.goToSearchStep.emit();
    }
}
